<template>
    <BaseSection
        width="page"
        class="section--accordion"
    >
        <BaseColumns>
            <template #column-accordion>
                <h2
                    v-if="title"
                    class="section__title"
                >
                    {{ title }}
                </h2>

                <div
                    v-if="description"
                    class="section__description"
                >
                    <span v-html="description" /> <!-- eslint-disable-line -->
                </div>

                <BaseAccordion :title="title" :items="accordion" />
            </template>

            <template v-if="image || showLocationsMap" #column-image>
                <BaseImage
                    v-if="singleImage && !showLocationsMap"
                    :src="singleImage.w768"
                    :src-tiny="singleImage.w32"
                    :srcset="`
                                        ${singleImage.w320} 320w,
                                        ${singleImage.w768} 768w,
                                        ${singleImage.w980} 980w,
                                        ${singleImage.w1260} 1260w
                                    `"
                    sizes="
                                        (max-width: 380px) 320px,
                                        768px
                                    "
                    :width="singleImage.width"
                    :height="singleImage.height"
                    :title="singleImage.title"
                    :alt="singleImage.alt"
                    :caption="singleImage.caption"
                    :credits="singleImage.credits"
                />
                <JCKLocationsMap
                    v-else-if="showLocationsMap"
                    :locations="jckLocations[0]"
                    class="accordion__locations-map"
                />
            </template>
        </BaseColumns>
    </BaseSection>
</template>

<script setup>

import { useHead } from '#imports';

useHead({
    htmlAttrs: {
        class: 'accordion--scroll'
    }
});
const props = defineProps({
    title: {
        type: String,
        default: null
    },
    description: {
        type: String,
        default: null
    },
    image: {
        type: Array,
        default: null
    },
    showLocationsMap: {
        type: Boolean,
        default: false
    },
    accordion: {
        type: Array,
        default: () => []
    },
    jckLocations: {
        type: Array,
        default: () => []
    }
});
const singleImage = ref(props.image && props.image[0] ? props.image[0] : null);

</script>

<style lang="less">
.section--accordion {
    @media @q-md-max {
        .columns[data-column-count='2'] {
            .columns__column:nth-child(1) {
                grid-column: 1;
                grid-row: 2;
            }

            .columns__column:nth-child(2) {
                grid-column: 1;
                grid-row: 1;
            }
        }
    }
}

.accordion__locations-map {
    width: 100%;
    min-height: 15.625rem;
}

.accordion--scroll {
    scroll-behavior: smooth;
    scroll-padding-top: 2.5rem;

    @media @q-md-min {
        scroll-padding-top: 7.5rem;
    }
}
</style>
