<template>
    <DnAccordion
        ref="accordion"
        :items="items"
        class="base-accordion"
        :scroll-offset="isLargeScreen ? '64' : '44'"
    >
        <template #title="{ item }">
            {{ item.title }}
        </template>

        <template #default="{ item }">
            <div v-html="item.content" /> <!-- eslint-disable-line vue/no-v-html -->
        </template>

        <template #icon>
            <BaseIcon icon="expand-more" />
        </template>
    </DnAccordion>
</template>

<script setup>
import DnAccordion from '@digitalnatives/accordion';
import { useMediaQuery } from '@vueuse/core';

const { $gtmPush } = useNuxtApp();

const isLargeScreen = useMediaQuery('(min-width: 1025px)');

const accordion = ref(null);

defineProps({
    items: {
        type: Array,
        required: true,
        default() { return []; }
    }
});

onMounted(() => {
    const summaries = accordion.value.$el.querySelectorAll('summary');

    summaries.forEach((summary) => {
        summary.addEventListener('click', (e) => {
            let subject = '';

            if (e.target.nodeName === 'SUMMARY') {
                subject = e.target.firstChild.innerText;
            }

            $gtmPush({ event: 'accordion_click', subject });
        });
    });
});
</script>

<style src="./BaseAccordion.less" lang="less"></style>
