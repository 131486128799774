<template>
    <div class="locations-map">
        <svg ref="map" class="locations-map__map" viewBox="0 0 375 250" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="375" height="250" transform="translate(0 0.499268)" fill="none" />
            <rect x="24" y="16.4993" width="327" height="218" fill="white" />
            <path d="M29 151.322L66.2786 106.103C67.3763 104.203 70.7278 100.082 77.9871 98.7958C81.4707 98.1787 83.8409 96.4937 85.418 94.5579M192.364 17.3984C171.826 31.2082 130.236 57.5126 112.966 65.9884C99.2002 72.7446 88.6711 82.6479 88.1589 88.4202C87.9051 90.0671 87.1274 92.4598 85.418 94.5579M85.418 94.5579L103.307 117.428C105.209 119.985 107.771 124.808 110.112 132.772C112.274 140.126 130.917 204.93 141.138 233.377M53.1797 216.873L149.658 187.547L185.824 174.832C187.721 174.165 189.493 173.22 190.758 171.657C201.648 158.2 234.066 95.9623 244.98 76.0718L246.809 72.8568C247.321 72.2723 246.491 68.7174 245.931 65.3308C245.114 60.3932 238.535 54.3347 236.51 52.8003L210.4 30.2968" stroke="#000F9F" stroke-width="0.995294" />
            <path d="M54.7717 47.0889L167.552 119.033C172.303 121.558 177.723 126.161 186.559 124.156C195.394 122.152 207.645 116.88 222.197 124.156C231.849 128.982 307.555 170.412 345 189.642" stroke="#000F9F" stroke-width="0.995294" />

            <BaseLink :url="localePath(`/${locations.portugueseSynagogue[0].uri}`)">
                <g ref="markerPS" class="locations-map__marker" filter="url(#marker-portuguese-synagogue)" @mouseover="showTooltip('portuguese-synagogue')" @mouseleave="hideTooltip()">
                    <path d="M104.721 96.557C107.058 100.012 110.593 103.754 115.327 107.782C120.061 103.754 123.597 100.012 125.935 96.557C128.272 93.1028 129.44 89.9056 129.44 86.9653C129.44 82.5549 128.021 79.0413 125.183 76.4245C122.347 73.8077 119.061 72.4993 115.327 72.4993C111.593 72.4993 108.308 73.8077 105.471 76.4245C102.633 79.0413 101.214 82.5549 101.214 86.9653C101.214 89.9056 102.383 93.1028 104.721 96.557Z" fill="#000F9F" />
                    <g clip-path="url(#clip0_2403_42971)">
                        <path d="M115.321 79.2461C117.054 79.2469 118.734 79.8469 120.076 80.9444C121.417 82.042 122.338 83.5695 122.682 85.2682C123.026 86.967 122.772 88.7324 121.963 90.2654C121.155 91.7983 119.841 93.0046 118.244 93.6797C116.409 94.455 114.34 94.4692 112.493 93.7193C110.647 92.9693 109.174 91.5166 108.399 89.6807C107.624 87.8448 107.609 85.7761 108.359 83.9297C109.109 82.0834 110.562 80.6105 112.398 79.8352C113.323 79.4444 114.317 79.2441 115.321 79.2461M115.321 77.7441C113.539 77.7441 111.796 78.2728 110.314 79.2632C108.831 80.2536 107.676 81.6613 106.994 83.3082C106.312 84.9552 106.133 86.7675 106.481 88.5159C106.829 90.2643 107.687 91.8703 108.948 93.1309C110.208 94.3914 111.814 95.2499 113.563 95.5976C115.311 95.9454 117.123 95.7669 118.77 95.0847C120.417 94.4025 121.825 93.2473 122.816 91.765C123.806 90.2828 124.335 88.5402 124.335 86.7575C124.335 84.367 123.385 82.0744 121.695 80.3841C120.004 78.6938 117.712 77.7441 115.321 77.7441V77.7441Z" fill="white" />
                    </g>
                </g>
            </BaseLink>

            <BaseLink :url="localePath(`/${locations.nationalHolocaustMuseum[0].uri}`)">
                <g ref="markerNHM" class="locations-map__marker" filter="url(#marker-national-holocaust-museum)" @mouseover="showTooltip('national-holocaust-museum')" @mouseleave="hideTooltip()">
                    <path d="M273.28 136.831C275.617 140.286 279.153 144.028 283.886 148.056C288.62 144.028 292.156 140.286 294.494 136.831C296.831 133.376 298 130.179 298 127.239C298 122.829 296.581 119.315 293.743 116.698C290.906 114.081 287.621 112.773 283.886 112.773C280.152 112.773 276.867 114.081 274.03 116.698C271.192 119.315 269.773 122.829 269.773 127.239C269.773 130.179 270.942 133.376 273.28 136.831Z" fill="#000F9F" />
                    <g clip-path="url(#clip1_2403_42971)">
                        <path d="M283.881 119.52C285.614 119.52 287.294 120.12 288.635 121.218C289.977 122.315 290.898 123.843 291.242 125.542C291.586 127.24 291.332 129.006 290.523 130.539C289.714 132.072 288.401 133.278 286.804 133.953C284.968 134.728 282.9 134.743 281.053 133.993C279.207 133.243 277.734 131.79 276.959 129.954C276.183 128.118 276.169 126.05 276.919 124.203C277.669 122.357 279.122 120.884 280.958 120.109C281.883 119.718 282.877 119.518 283.881 119.52M283.881 118.018C282.098 118.018 280.356 118.546 278.873 119.537C277.391 120.527 276.236 121.935 275.554 123.582C274.872 125.229 274.693 127.041 275.041 128.789C275.389 130.538 276.247 132.144 277.508 133.404C278.768 134.665 280.374 135.523 282.123 135.871C283.871 136.219 285.683 136.04 287.33 135.358C288.977 134.676 290.385 133.521 291.375 132.038C292.366 130.556 292.894 128.814 292.894 127.031C292.894 124.64 291.945 122.348 290.254 120.658C288.564 118.967 286.272 118.018 283.881 118.018V118.018Z" fill="white" />
                    </g>
                </g>
            </BaseLink>

            <BaseLink :url="localePath(`/${locations.hollandscheSchouwburg[0].uri}`)">
                <g ref="markerHS" class="locations-map__marker" filter="url(#marker-hollandsche-schouwburg)" @mouseover="showTooltip('hollandsche-schouwburg')" @mouseleave="hideTooltip()">
                    <path d="M262.054 180.667C264.391 184.122 267.927 187.864 272.66 191.892C277.394 187.864 280.93 184.122 283.268 180.667C285.605 177.213 286.774 174.016 286.774 171.075C286.774 166.665 285.355 163.151 282.517 160.535C279.68 157.918 276.394 156.609 272.66 156.609C268.926 156.609 265.641 157.918 262.804 160.535C259.966 163.151 258.547 166.665 258.547 171.075C258.547 174.016 259.716 177.213 262.054 180.667Z" fill="#000F9F" />
                    <g clip-path="url(#clip2_2403_42971)">
                        <path d="M272.654 163.356C274.388 163.357 276.067 163.957 277.409 165.055C278.75 166.152 279.671 167.68 280.015 169.378C280.359 171.077 280.105 172.842 279.297 174.375C278.488 175.908 277.174 177.115 275.578 177.79C273.742 178.565 271.673 178.579 269.827 177.829C267.98 177.079 266.507 175.627 265.732 173.791C264.957 171.955 264.943 169.886 265.693 168.04C266.443 166.193 267.895 164.721 269.731 163.945C270.656 163.555 271.65 163.354 272.654 163.356M272.654 161.854C270.872 161.854 269.129 162.383 267.647 163.373C266.165 164.364 265.009 165.771 264.327 167.418C263.645 169.065 263.467 170.878 263.814 172.626C264.162 174.374 265.021 175.98 266.281 177.241C267.542 178.502 269.148 179.36 270.896 179.708C272.644 180.056 274.457 179.877 276.104 179.195C277.751 178.513 279.158 177.357 280.149 175.875C281.139 174.393 281.668 172.65 281.668 170.868C281.668 168.477 280.718 166.185 279.028 164.494C277.338 162.804 275.045 161.854 272.654 161.854V161.854Z" fill="white" />
                    </g>
                </g>
            </BaseLink>

            <BaseLink :url="localePath(`/${locations.jewishMuseumJunior[0].uri}`)">
                <g ref="markerJM" class="locations-map__marker" filter="url(#marker-jewish-museum)" @mouseover="showTooltip('jewish-museum')" @mouseleave="hideTooltip()">
                    <path d="M70.5071 140.393C72.844 143.849 76.3794 147.591 81.1132 151.619C85.847 147.591 89.383 143.849 91.7211 140.393C94.058 136.939 95.2264 133.742 95.2264 130.802C95.2264 126.391 93.8075 122.878 90.9695 120.261C88.1328 117.644 84.8473 116.336 81.1132 116.336C77.3791 116.336 74.0937 117.644 71.2569 120.261C68.419 122.878 67 126.391 67 130.802C67 133.742 68.169 136.939 70.5071 140.393Z" fill="#000F9F" />
                    <g clip-path="url(#clip3_2403_42971)">
                        <path d="M81.1076 123.083C82.8408 123.083 84.5204 123.683 85.8619 124.781C87.2033 125.878 88.1241 127.406 88.4681 129.105C88.8122 130.803 88.5583 132.569 87.7496 134.102C86.9409 135.635 85.6271 136.841 84.0308 137.516C82.1949 138.291 80.1262 138.306 78.2798 137.556C76.4335 136.806 74.9606 135.353 74.1853 133.517C73.4101 131.681 73.3958 129.613 74.1458 127.766C74.8958 125.92 76.3485 124.447 78.1844 123.672C79.1093 123.281 80.1035 123.081 81.1076 123.083M81.1076 121.581C79.3249 121.581 77.5823 122.109 76.1 123.1C74.6178 124.09 73.4625 125.498 72.7803 127.145C72.0981 128.792 71.9196 130.604 72.2674 132.352C72.6152 134.101 73.4737 135.707 74.7342 136.967C75.9947 138.228 77.6008 139.086 79.3492 139.434C81.0976 139.782 82.9099 139.603 84.5568 138.921C86.2038 138.239 87.6115 137.084 88.6019 135.601C89.5923 134.119 90.1209 132.377 90.1209 130.594C90.1209 128.203 89.1713 125.911 87.481 124.221C85.7906 122.53 83.4981 121.581 81.1076 121.581V121.581Z" fill="white" />
                    </g>
                </g>
            </BaseLink>

            <defs>
                <filter
                    id="marker-portuguese-synagogue"
                    x="99.0755"
                    y="72.4993"
                    width="32.503"
                    height="43.3018"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="5.8805" />
                    <feGaussianBlur stdDeviation="1.06918" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2403_42971" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2403_42971" result="shape" />
                </filter>

                <filter
                    id="marker-national-holocaust-museum"
                    x="267.635"
                    y="112.773"
                    width="32.503"
                    height="43.3018"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="5.8805" />
                    <feGaussianBlur stdDeviation="1.06918" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2403_42971" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2403_42971" result="shape" />
                </filter>

                <filter
                    id="marker-hollandsche-schouwburg"
                    x="256.409"
                    y="156.609"
                    width="32.503"
                    height="43.3018"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="5.8805" />
                    <feGaussianBlur stdDeviation="1.06918" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2403_42971" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2403_42971" result="shape" />
                </filter>

                <filter
                    id="marker-jewish-museum"
                    x="64.8616"
                    y="116.336"
                    width="32.503"
                    height="43.3018"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="5.8805" />
                    <feGaussianBlur stdDeviation="1.06918" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2403_42971" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2403_42971" result="shape" />
                </filter>
            </defs>
        </svg>

        <svg
            v-show="currentTooltip === 'portuguese-synagogue'"
            ref="tooltipPS"
            class="locations-map__tooltip locations-map__tooltip-portuguese-synagogue"
            x="126"
            y="63"
            width="114"
            height="55"
            viewBox="0 0 114 55"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_2403_42374)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M109.32 2.91294C109.32 2.02745 108.602 1.30962 107.716 1.30962H16.8962C16.0108 1.30962 15.2929 2.02745 15.2929 2.91294V16.4927C15.2929 17.0421 15.0116 17.5532 14.5475 17.8472L6.72876 22.799C5.73481 23.4285 5.73481 24.8786 6.72876 25.5081L14.5475 30.4599C15.0116 30.7539 15.2929 31.265 15.2929 31.8144V43.4614C15.2929 44.3468 16.0108 45.0647 16.8962 45.0647H107.716C108.602 45.0647 109.32 44.3468 109.32 43.4614V2.91294Z" fill="#000F9F" />
                <LocationLogoLogosPortugueseSynagogue :x="19" :y="localeValue(5, 7)" :width="localeValue(88, 85)" />
            </g>
            <defs>
                <filter
                    id="filter0_d_2403_42374"
                    x="0.45206"
                    y="0.618153"
                    width="113.016"
                    height="53.435"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-0.691417" dy="4.1485" />
                    <feGaussianBlur stdDeviation="2.41996" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.254167 0 0 0 0 0.252049 0 0 0 0 0.252049 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2403_42374" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2403_42374" result="shape" />
                </filter>
            </defs>
        </svg>

        <svg
            v-show="currentTooltip === 'jewish-museum'"
            ref="tooltipJM"
            class="locations-map__tooltip locations-map__tooltip-jewish-museum"
            width="139"
            height="61"
            viewBox="0 0 139 61"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_2403_42366)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M78.3404 7.14118C77.8454 7.14118 77.3849 6.88775 77.1201 6.46959L74.0814 1.67157C73.5142 0.776116 72.2079 0.776115 71.6408 1.67157L68.602 6.46959C68.3372 6.88775 67.8767 7.14118 67.3817 7.14118H6.44444C5.6467 7.14118 5 7.78788 5 8.58562V50.4745C5 51.2723 5.6467 51.919 6.44444 51.919H133.556C134.353 51.919 135 51.2723 135 50.4745V8.58563C135 7.78788 134.353 7.14118 133.556 7.14118H78.3404Z" fill="#000F9F" />
                <LocationLogoLogosJewishMuseumJuniorVariant
                    :color="logoColor"
                    compact="true"
                    :x="12"
                    :y="localeValue(15, 14)"
                    :width="115"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_2403_42366"
                    x="0.0167639"
                    y="0.377095"
                    width="138.721"
                    height="59.6396"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-0.622905" dy="3.73743" />
                    <feGaussianBlur stdDeviation="2.18017" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.254167 0 0 0 0 0.252049 0 0 0 0 0.252049 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2403_42366" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2403_42366" result="shape" />
                </filter>
            </defs>
        </svg>

        <svg
            v-show="currentTooltip === 'hollandsche-schouwburg'"
            ref="tooltipHS"
            class="locations-map__tooltip locations-map__tooltip-hollandsche-schouwburg"
            width="134"
            height="59"
            viewBox="0 0 134 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_2403_42390)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M118.983 2.60332C118.983 1.71783 118.266 1 117.38 1H7.94072C7.05523 1 6.3374 1.71783 6.3374 2.60332V47.8065C6.3374 48.692 7.05523 49.4099 7.94072 49.4099H117.38C118.266 49.4099 118.983 48.692 118.983 47.8065V33.881C118.983 33.3395 119.257 32.8345 119.71 32.5385L128.951 26.5057C129.921 25.873 129.921 24.4533 128.951 23.8206L119.71 17.7878C119.257 17.4918 118.983 16.9868 118.983 16.4452V2.60332Z" fill="#000F9F" />
                <LocationLogoLogosHollandscheSchouwburg :x="12" :y="localeValue(8, 8)" :width="100" />
            </g>
            <defs>
                <filter
                    id="filter0_d_2403_42390"
                    x="0.806064"
                    y="0.308582"
                    width="133.021"
                    height="58.0898"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-0.691417" dy="4.1485" />
                    <feGaussianBlur stdDeviation="2.41996" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.254167 0 0 0 0 0.252049 0 0 0 0 0.252049 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2403_42390" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2403_42390" result="shape" />
                </filter>
            </defs>
        </svg>

        <svg
            v-show="currentTooltip === 'national-holocaust-museum'"
            ref="tooltipNHM"
            class="locations-map__tooltip locations-map__tooltip-national-holocaust-museum"
            width="105"
            height="81"
            viewBox="0 0 105 81"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_2403_42382)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M100.167 2.53398C100.167 1.64849 99.4496 0.930664 98.5641 0.930664H7.74394C6.85845 0.930664 6.14062 1.64849 6.14062 2.53398V61.7016C6.14062 62.5871 6.85845 63.3049 7.74394 63.3049H46.4376C46.9791 63.3049 47.4841 63.5783 47.7801 64.0318L52.5075 71.2733C53.1402 72.2424 54.56 72.2424 55.1926 71.2733L59.92 64.0318C60.2161 63.5783 60.721 63.3049 61.2626 63.3049H98.5641C99.4496 63.3049 100.167 62.5871 100.167 61.7016V2.53398Z" fill="#000F9F" />
                <LocationLogoLogosNationalHolocaustMuseum :x="localeValue(13, 11)" :y="localeValue(6, 5)" :width="localeValue(84, 86)" />
            </g>
            <defs>
                <filter
                    id="filter0_d_2403_42382"
                    x="0.609287"
                    y="0.239246"
                    width="103.707"
                    height="80.7494"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-0.691417" dy="4.1485" />
                    <feGaussianBlur stdDeviation="2.41996" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.254167 0 0 0 0 0.252049 0 0 0 0 0.252049 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2403_42382" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2403_42382" result="shape" />
                </filter>
            </defs>
        </svg>
    </div>
</template>

<script setup>
import { nextTick } from 'vue';
import { getCurrentTheme } from '~/composables/useColorTheme';

const { locale } = useI18n();
const localePath = useLocalePath();

const colorTheme = getCurrentTheme;
const logoColor = ref('blue');

const props = defineProps({
    locations: {
        type: Object,
        default: () => {}
    }
});

const map = ref();

const tooltipSpacing = 2;

// Marker refs
const markerJM = ref();
const markerPS = ref();
const markerHS = ref();
const markerNHM = ref();

// Tooltip refs
const tooltipJM = ref();
const tooltipPS = ref();
const tooltipHS = ref();
const tooltipNHM = ref();

const currentTooltip = ref('jewish-museum');

let resizeObserver;

const showTooltip = (name) => {
    currentTooltip.value = name;
};

const hideTooltip = () => {
    currentTooltip.value = null;
};

const positionTooltips = () => {
    if (!process.client) {
        return;
    }

    // Map + Marker rects
    const rectMap = map.value.getBoundingClientRect(),
        rectJM = markerJM.value.getBoundingClientRect(),
        rectPS = markerPS.value.getBoundingClientRect(),
        rectHS = markerHS.value.getBoundingClientRect(),
        rectNHM = markerNHM.value.getBoundingClientRect();

    tooltipJM.value.style.top = ((rectJM.y - rectMap.y) + (rectJM.height / 2) + tooltipSpacing) + 'px';
    tooltipJM.value.style.left = ((rectJM.x - rectMap.x) - (tooltipJM.value.attributes.width.value / 2) - (rectJM.width / 2) - 3) + 'px';

    tooltipPS.value.style.top = ((rectPS.y - rectMap.y) - (rectPS.height / 2)) + 'px';
    tooltipPS.value.style.left = (rectPS.x - rectMap.x) + 'px';

    tooltipHS.value.style.top = ((rectHS.y - rectMap.y) - (rectHS.height / 2)) + 'px';
    tooltipHS.value.style.left = ((rectHS.x - rectMap.x) - (tooltipHS.value.attributes.width.value) - (rectHS.width) - tooltipSpacing) + 'px';

    tooltipNHM.value.style.top = ((rectNHM.y - rectMap.y) - (tooltipNHM.value.attributes.height.value) - (rectNHM.height / 2)) + 'px';
    tooltipNHM.value.style.left = ((rectNHM.x - rectMap.x) - (tooltipNHM.value.attributes.width.value / 2) - (rectNHM.width / 2)) + 'px';
};

const localeValue = (valueNl, valueEn) => locale.value === 'nl' ? valueNl : valueEn;

const setLogoColor = () => {
    logoColor.value = unref(colorTheme.value) === 'inverse' ? 'blue' : 'white';
};

onMounted(async() => {
    if (!process.client) {
        return;
    }

    await nextTick();
    positionTooltips();

    resizeObserver = new ResizeObserver(positionTooltips);

    resizeObserver.observe(map.value);

    // Listen to body resize to update when mobile orientation changes.
    resizeObserver.observe(document.querySelector('body'));

    setLogoColor();

    watch(colorTheme.value, () => {
        setLogoColor();
    });
});

onUnmounted(() => {
    resizeObserver.disconnect();
});
</script>

<style lang="less">
.locations-map {
    position: relative;

    rect[fill='white'] {
        fill: var(--color-theme-background);
    }

    path[stroke='#000F9F'] {
        stroke: var(--color-theme-text);
    }
}

.locations-map__map {
    transform: scale(1.175);
}

.locations-map__marker {
    cursor: pointer;

    path[fill='white'] {
        fill: var(--color-theme-background);
    }

    path[fill='#000F9F'] {
        fill: var(--color-theme-text);
    }
}

.locations-map__tooltip {
    position: absolute;
    z-index: 1;

    // Tooltip container
    g > path[fill='#000F9F'] {
        fill: var(--color-theme-text);
    }
}

.locations-map__tooltip g svg,
.locations-map__tooltip g svg * {
    fill: var(--color-theme-background);
}
</style>
